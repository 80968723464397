import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledContact = styled.div`
	padding: 0 6rem;
	/* 		nav 6.2  masthead 7  footer 3 */
	height: calc(100vh - 16.2rem);
	min-height: 490px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	&::after {
		content: '';
		background-image: url('/img/General_blue_bar.svg');
		background-size: 100%;
		background-position: center left;
		background-repeat: no-repeat;
		position: absolute;
		left: -5px;
		bottom: 20px;
		bottom: calc((100vh - 16.2rem) * 0.1);
		width: 280px;
		height: 150px;
		@media all and (min-width: 1200px) {
			width: 320px;
		}
		@media all and (min-width: 1600px) {
			width: 380px;
		}
	}
	.col-container {
		display: flex;
	}
	.left {
		width: 60%;
		padding-right: 6rem;
		h2 {
			color: ${(p) => p.theme.color.headingRed};
		}
		h3 {
			font-weight: 700;
			font-size: 1.1rem;
			margin-top: 1rem;
		}
		p {
			margin: 0.5rem 0 2rem;
			font-size: 1.1rem;
			line-height: 1.4rem;
		}
		a {
			color: ${(p) => p.theme.color.red};
			font-size: 2rem;
			font-weight: 700;
			line-height: 2.5rem;
		}
	}
	.right {
		width: 40%;
		h3 {
			font-weight: 700;
			font-size: 1.1rem;
		}
		hr {
			width: 200px;
			height: 1px;
			background-color: ${(p) => p.theme.color.grey};
			text-align: left;
		}
		.person {
			h4 {
				font-weight: 700;
			}
			p {
				color: ${(p) => p.theme.color.blue};
				line-height: 1.4rem;
			}
			a {
				display: block;
				color: ${(p) => p.theme.color.grey};
				line-height: 1.4rem;
			}
		}
	}
	@media all and (min-width: 1200px) {
		padding: 0 10rem;
		.left {
			width: 50%;
			padding-right: 8rem;
		}
	}
	@media all and (min-width: 1200px) {
		padding: 0 15rem;
	}
	@media all and (min-width: 1600px) {
		/* 		 nav 8.95  masthead 9  footer 6 */
		height: calc(100vh - 23.95rem);
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		h3 {
			font-size: 1.4rem !important;
			line-height: 2rem;
		}
		p,
		a {
			font-size: 1.3rem;
			line-height: 1.9rem !important;
		}
		.left,
		.right {
			max-width: 650px;
		}
	}
	@media all and (max-width: 768px) {
		padding: 0 3rem;
		.left {
			padding-right: 3rem;
		}
	}
	@media all and (max-width: 568px) {
		height: auto;
		min-height: auto;
		padding: 3rem 6rem;
		&::after {
			display: none;
		}
		.col-container {
			flex-direction: column;
			.left {
				width: 100%;
				padding: 0;
			}
			.right {
				width: 100%;
			}
		}
	}
	@media all and (max-width: 468px) {
		padding: 3rem 4rem;
	}
	@media all and (max-height: 950px) and (min-width: 1600px) {
		/* 		 nav 8.95  masthead 9  footer 6 */
		min-height: calc(100vh - 23.95rem);
		height: auto;
		padding: 2rem 15rem;
	}
`;

const Contact = () => {
	return (
		<StyledContact>
			<div className="col-container">
				<div className="left">
					<h2>READY TO GET ON THE ICE?</h2>
					<p>
						First Line Rinks builds the best custom ice rinks, in nearly any
						location. Looking for a rink? Let’s chat! You can get a free quote
						within 24 hours.
					</p>
					<h3>Give us a call:</h3>
					<a href="tel:7057166510">(705) 716-6510</a>
					<h3>Or send us your info:</h3>
					<Link to="/our-rinks#form">Fill out form ></Link>
				</div>
				<div className="right">
					<h3>Connect with Our Team</h3>
					<hr />
					<div className="person">
						<h4>JACOB MCKENDRY</h4>
						<p>Director of Sales</p>
						<a href="tel:7057166510">(705) 716-6510</a>
						<a href="mailto:jacob@firstlinerinks.com">
							jacob@firstlinerinks.com
						</a>
					</div>
					<hr />
					<div className="person">
						<h4>TYLER AGOSTINI</h4>
						<p>Director of Operations</p>
						<a href="tel:7057188265">(705) 718-8265</a>
						<a href="mailto:tyler@firstlinerinks.com">
							tyler@firstlinerinks.com
						</a>
					</div>
					<hr />
					<p>
						First Line Rinks operates out of Barrie, ON. We service Simcoe,
						Muskoka, York, the GTA, and pretty much anywhere else in Canada!
					</p>
				</div>
			</div>
		</StyledContact>
	);
};

export default Contact;
