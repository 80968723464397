import React from 'react';

import Masthead from '../components/masthead';
import Contact from '../components/contact';

const ContactTemplate = () => {
	return (
		<>
			<Masthead title="CONTACT US" img="/img/FLR_Contact_header.jpg" />
			<Contact />
		</>
	);
};

export default ContactTemplate;
