import React from 'react';

import Layout from '../components/layout';
import ContactTemplate from '../templates/contact-template';
import SEO from '../components/seo';

const ContactPage = () => (
	<Layout>
		<SEO
			title="Contact"
			description="Wondering about your ice rink options and potential costs? Contact us to schedule a no obligation estimate today."
		/>
		<ContactTemplate />
	</Layout>
);

export default ContactPage;
